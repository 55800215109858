// Dependencies
import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
// Hooks & Helpers
import { useResetHeader } from "../hooks/useUpdateHeader"
import useSettings from "../hooks/useSettings"
// Components
import Layout from "../components/Layout"
import Filters from "../components/Filters"
import JournalLink from "../components/JournalLink"


const Journal = ({ data }) => {

	// Content
	const journal = data.allContentfulJournal.nodes
	const { journalIntroduction } = useSettings()

	// Resets header colours back to black
	useResetHeader()

	return (
		<Layout headerTitle="Journal" hideLogo showFilterButton title={`Journal | AFK`} theme={{
			background: 'var(--green-bg)',
			highlight: 'var(--green)'
		}}>
			<div className="journal header-offset">
				<div className="guttersx4 outerx4">
					<h1>{journalIntroduction.text}</h1>
				</div>
				<div className="grid-3 outerx4 guttersx4">
					{journal.map((entry, index) => (
						<JournalLink {...entry} key={`${entry.id}${index}`} />
					))}
				</div>
			</div>

			<Filters type="journal" />
		</Layout>
	)
}

Journal.propTypes = {
  data: PropTypes.object.isRequired
}

export default Journal

export const JournalPageQuery = graphql`
  query JournalQuery {
		allContentfulJournal(
			sort: {fields: date, order: DESC}
    	filter: {contentful_id: {ne: "15FihP8swdIT9ICONlzZXU"}}
		) {
			nodes {
				...JournalLinkFragment
			}
		}
  }
`